import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import { composeUrl } from "../../utils/api";
import { getClaimValue } from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import LoadingSpinner from "../Shared/LoadingSpinner/LoadingSpinner";
import Mail from "../../images/mail-02.svg";
import Trash from "../../images/trash-01.svg";
import Edit from "../../images/edit-pencil.svg";
import { getFeatureFlag } from "../../utils/featureFlag";
import ConfirmDeleteMemberModal from "./ConfirmDeleteMemberModal";
import EditMemberModal from "./EditMemberModal";

export interface MemberSummary {
    id: number;
    statusId: number;
    statusName: string;
    startDate: string;
    endDate?: string;
    externalId: string;
    firstName: string;
    lastName: string;
    email: string;
}

interface MemberStats {
    eligibleMembersCount: number;
    enrolledMembersCount: number;
    optedOutMembersCount: number;
    agreedMembersCount: number;
}

function MemberSearch() {
    const auth = useAuth();
    const [members, setMembers] = useState<MemberSummary[]>([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [resendMemberInviteEnabled, setResendMemberInviteEnabled] = useState(false);
    const [memberToDelete, setMemberToDelete] = useState<MemberSummary | null>(null);
    const [deleteError, setDeleteError] = useState("");
    const [memberStats, setMemberStats] = useState<MemberStats | null>(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [memberToEdit, setMemberToEdit] = useState<MemberSummary | null>(null);
    const [editMessage, setEditMessage] = useState("");
    const [editError, setEditError] = useState("");

    const fetchMembers = useCallback(
        async (query: string = "") => {
            try {
                setLoading(true);
                setError("");
                const orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
                const url = composeUrl(
                    `organisations/${orgUuid}/members/filteredMembers?search=${encodeURIComponent(
                        query
                    )}&page=${page}&pageSize=${pageSize}`
                );
                const response = await fetch(url, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${auth.user?.access_token}`,
                        "Content-Type": "application/json",
                    },
                });
                if (!response.ok) {
                    setError("Unable to fetch members");
                    return;
                }
                const data: MemberSummary[] = await response.json();
                setMembers(data);
            } catch {
                setError("An error occurred while fetching members");
            } finally {
                setLoading(false);
            }
        },
        [auth, page, pageSize]
    );

    const fetchMemberStats = useCallback(async () => {
        try {
            const orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
            const statsUrl = composeUrl(`organisations/${orgUuid}/dashboard/memberStats`);
            const response = await fetch(statsUrl, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${auth.user?.access_token}`,
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) return;
            const data: MemberStats = await response.json();
            setMemberStats(data);
        } catch {
            console.error("Error occurred while fetching member stats");
        }
    }, [auth]);

    const getLimitColorClass = (eligible: number, agreed: number) => {
        if (agreed === 0) return "text-error-red";
        const ratio = (eligible / agreed) * 100;
        if (ratio >= 100) return "text-error-red";
        if (ratio >= 90) return "text-lime-orange";
        return "text-primary-text";
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            fetchMembers(searchQuery);
        }, 400); // debounce input
        return () => clearTimeout(timeoutId);
    }, [searchQuery, page, pageSize, fetchMembers]);

    useEffect(() => {
        fetchMemberStats();
        (async () => {
            const featureFlags = await getFeatureFlag(auth);
            setResendMemberInviteEnabled(featureFlags.resendMemberInviteEnabled);
        })();
    }, [auth, fetchMemberStats]);

    async function handleDeleteConfirmed() {
        if (!memberToDelete) return;
        try {
            setDeleteError("");
            setLoading(true);
            const orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
            const response = await fetch(composeUrl(`organisations/${orgUuid}/members/${memberToDelete.externalId}`), {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${auth.user?.access_token}`,
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                setDeleteError("Unable to delete member");
                return;
            }
            setMembers((prev) =>
                prev.map((m) =>
                    m.externalId === memberToDelete.externalId ? { ...m, statusName: "Removed" } : m
                )
            );
        } catch {
            setDeleteError("An error occurred while deleting the member");
        } finally {
            setLoading(false);
            setMemberToDelete(null);
        }
    }

    function handleOpenDeleteModal(member: MemberSummary) {
        setDeleteError("");
        setMemberToDelete(member);
    }

    function handleOpenEditModal(member: MemberSummary) {
        setMemberToEdit(member);
        setEditModalOpen(true);
        setEditMessage("");
        setEditError("");
    }

    function handleEditSuccess() {
        setEditModalOpen(false);
        setMemberToEdit(null);
        setTimeout(() => {
            fetchMembers(searchQuery);
        }, 200);
        setEditMessage("Member updated successfully.");
        setEditError("");
    }


    function handleEditFailure() {
        setEditModalOpen(true);
        setEditMessage("");
        setEditError("An error occurred while updating the member.");
    }

    return (
        <LoadingSpinner loading={loading}>
            {error && <p className="text-error">{error}</p>}
            {editMessage && <p className="text-green-600">{editMessage}</p>}
            {editError && <p className="text-error-red">{editError}</p>}

            <ConfirmDeleteMemberModal
                show={!!memberToDelete}
                member={memberToDelete ?? undefined}
                onConfirm={handleDeleteConfirmed}
                onCancel={() => setMemberToDelete(null)}
                errorMessage={deleteError}
            />

            <EditMemberModal
                isOpen={editModalOpen}
                setIsOpen={setEditModalOpen}
                member={memberToEdit}
                onEditSuccess={handleEditSuccess}
                onEditFailure={handleEditFailure}
                setLoading={setLoading}
            />

            {members.length === 0 && !error && (
                <div className="text-center py-md">
                    <p>You don't have any members yet. Upload a csv file or create users individually.</p>
                </div>
            )}

            {members.length > 0 && (
                <div>
                    <div className="w-full flex flex-row justify-between my-4">
                        {memberStats && (
                            <div className="p-4 lg:p-4 bg-super-light-grey rounded-xl">
                                <div className="lg:min-w-[8rem]">
                                    <h3 className={`lg:text-h3 mb-2 ${getLimitColorClass(
                                        memberStats.eligibleMembersCount,
                                        memberStats.agreedMembersCount
                                    )}`}>
                                        {String(memberStats.eligibleMembersCount).padStart(2, "0")}/
                                        {String(memberStats.agreedMembersCount).padStart(2, "0")}
                                    </h3>
                                    <p className="lg:text-h5 lg:font-normal whitespace-nowrap">
                                        Eligible / Agreed Members
                                    </p>
                                </div>
                            </div>
                        )}
                        <div className="w-full flex flex-row justify-end my-5">
                            <input
                                type="text"
                                placeholder="Start typing your search criteria..."
                                className="w-3/4 xl:w-1/2 rounded-md border border-gray-300 p-2"
                                value={searchQuery}
                                onChange={(e) => {
                                    setPage(1);
                                    setSearchQuery(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <table className="w-full">
                        <thead className="bg-super-light-grey">
                        <tr className="h-2xl text-left text-primary-text">
                            <th className="px-md py-sm border-b-2 border-primary-text">Name</th>
                            <th className="px-md py-sm border-b-2 border-primary-text">Email</th>
                            <th className="px-md py-sm border-b-2 border-primary-text">Status</th>
                            <th className="px-md py-sm border-b-2 border-primary-text">Employee ID</th>
                            <th className="px-md py-sm border-b-2 border-primary-text" />
                        </tr>
                        </thead>
                        <tbody>
                        {members.map((member) => (
                            <tr key={member.id} className="text-left bg-pure-white even:bg-super-light-grey h-2xl">
                                <td className="px-md py-sm border-y-2 border-background-blu">
                                    {member.firstName} {member.lastName}
                                </td>
                                <td className="px-md py-sm border-y-2 border-background-blu">{member.email}</td>
                                <td className="px-md py-sm border-y-2 border-background-blu">{member.statusName}</td>
                                <td className="px-md py-sm border-y-2 border-background-blu">{member.externalId}</td>
                                <td className="px-md py-sm border-y-2 border-background-blu">
                                    <div className="w-full hidden lg:flex items-end space-x-4">
                                        {member.statusName === "Invited" && resendMemberInviteEnabled && (
                                            <img
                                                className="hover:cursor-pointer"
                                                src={Mail}
                                                alt="Resend welcome email"
                                            />
                                        )}
                                        <img
                                            className="hover:cursor-pointer"
                                            src={Edit}
                                            alt="Edit user"
                                            onClick={() => handleOpenEditModal(member)}
                                        />
                                        <img
                                            className="hover:cursor-pointer"
                                            src={Trash}
                                            alt="Delete user"
                                            onClick={() => handleOpenDeleteModal(member)}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className="flex justify-between items-center mt-4 mb-5">
                        <div>
                            <button disabled={page <= 1} onClick={() => setPage(page - 1)}>
                                Previous
                            </button>
                            <span className="mx-2">Page {page}</span>
                            <button disabled={members.length < pageSize} onClick={() => setPage(page + 1)}>
                                Next
                            </button>
                        </div>
                        <div>
                            <label htmlFor="pageSize">Page Size: </label>
                            <select
                                id="pageSize"
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                    setPage(1);
                                }}
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                    </div>
                </div>
            )}
        </LoadingSpinner>
    );
}

export default MemberSearch;